import { useState } from "react";
import classes from "./SearchBar.module.scss";
import searchIcon from "@/assets/icons/icon-search.svg";
import { usePrTableContext } from "../../context/PRTableContext";

export const SearchBar = () => {
  const { setSearchFilter, setCurrentPage } = usePrTableContext();

  const [searchQuery, setSearchQuery] = useState("");
  return (
    <div className={classes.searchBarContainer}>
      <img
        src={searchIcon}
        className={classes.searchBarIcon}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          // set context search filter to the search query on submit
          setSearchFilter(searchQuery);
          // reset current page to 0
          setCurrentPage(0);
        }}
        autoComplete="off"
        className={classes.searchBarForm}
      >
        <input
          className={classes.searchBarInput}
          type="text"
          name="searchbar"
          placeholder="Search"
          data-testid="search-bar-input"
          value={searchQuery}
          // set local search query on type
          onChange={(ev) => setSearchQuery(ev.target.value)}
        />
      </form>
    </div>
  );
}