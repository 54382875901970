// to be moved to platform styleguide if needed

// @ts-ignore
import { Tooltip } from "@aiops/styleguide";
import styles from "./StyledTooltip.module.scss";

const StyledTooltip = ({ children, title, ...props }) => {
    return (
        <Tooltip
            classes={{popper: styles.tooltipPopper}}
            title={title}
            {...props}>
                {children}
        </Tooltip>
    );
}

export default StyledTooltip;