// @ts-ignore
import { Typography } from "@aiops/styleguide";
import styles from "./DetailsOverview.module.scss";

type DetailsOverviewProps = {
    configMap: Array<{ title: string, field: string, style: string }>;
    details?: Record<string, any>;
}

export const DetailsOverview = ({ configMap, details } : DetailsOverviewProps) => {

  return (
    <div className={styles.overview}>
      {configMap?.map((config, i) => {
        return (
          <div className="groupRow" key={`groupRow-${config.title}`}>
            {i !== 0 && <div className="divider" />}
            <div key={config.title} className="dataGroup">
              <Typography className="label" variant="caption1c">
                {config.title}
              </Typography>
              <Typography className={`details ${config.style}`} variant="paragraph1-bold">
                {details?.[config.field]}
              </Typography>
            </div>
          </div>
        )
      })}
    </div>
  )
};