import { Outlet } from 'react-router-dom';

const ProcurementExecution  = () => {
    return(
        <>
            <Outlet/>
        </>
    )
}

export default ProcurementExecution;