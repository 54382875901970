/**
 * This file is used to provide the context to the entire application.
 * This would have the following:
 * 1. configuration : Configurable values for the application like daysOpenThreshold
 *
 */

import { ProcurementContext } from "./procurement.context";
import { useMemo, useState } from "react";
import { ProcurementConfigurationType } from "@/context/type";
// @ts-ignore
import { useAuth } from "@aiops/styleguide";
import { DEFAULT_PROCUREMENT_CONFIGURATION } from "@/context/context.constant";
import { setAuthToken, setBaseUrl } from "@/services/api.setup";

export const ProcurementContextProvider = ({ endpointBaseUrl, children }: { endpointBaseUrl: string, children: React.ReactNode }) => {
    /* This default configuration needs to be updated if and when backend API os ready */
    const [configurations, setConfigurations] = useState<ProcurementConfigurationType>({
        ...DEFAULT_PROCUREMENT_CONFIGURATION
    });
    const auth = useAuth();
    setBaseUrl(endpointBaseUrl);
    setAuthToken(auth.getIdToken())

    const procurementContext = useMemo(() => ({
        configurations,
        setConfigurations,
    }), [configurations]);

    return <ProcurementContext.Provider value={procurementContext}>{children}</ProcurementContext.Provider>;
}