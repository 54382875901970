import { useQuery } from "@tanstack/react-query";
import { getRequisitionDetails } from "@/services/requisition.requests";
// @ts-ignore
import { FilterButtonProps } from '@aiops/styleguide';
import { getStatusQueryParam } from "@/services/apihelper";
import { FilterType } from "@/modules/LandingPage/components/DashboardTable/type";

export const useRequisitionDetails = (
  id: string,
  recommendationFilter: FilterButtonProps[],
) => {
  const details = useQuery({
    queryKey: ["reqDetails", id, recommendationFilter],
    queryFn: () => getRequisitionDetails(id, getStatusQueryParam(recommendationFilter, "recommendationFilter" as FilterType),),
    refetchOnWindowFocus: false,
  });
  // Mutation will be added here to update line item status in the future
  return {
    details
  };
};