//@ts-ignore
import { StyledIconButton } from "@aiops/styleguide";
import { leftTableActionBtnConfig, rightTableActionBtnConfig, TableActionBtnConfigType } from "./config";
import styles from "./TableActionButtons.module.scss";
import { usePrTableContext } from "../../context/PRTableContext";
import { getIsActionButtonDisabled } from "./helper";
import { StyledTooltip } from "@/components";
import { useIsFetching } from "@tanstack/react-query";

export const StyledActionButton = ({ onClick, text, icon, className }) => {
  const { selectedReqIds } = usePrTableContext();
  const disabled = getIsActionButtonDisabled({ selectedReqIds, text });
  return (
    <StyledIconButton
      data-testid={`table-action-btn-${text}`}
      isSquare={true}
      onClick={onClick}
      disabled={disabled}
      buttonProps={{ style: { height: "32px", width: "32px" } }}
      icon={
        <StyledTooltip
          title={text}
          key={`tooltip-${text}`}
          placement="top"
          arrow
        >
          <img src={icon} alt={text} className={`icon ${className}`} />
        </StyledTooltip>
      }
    />
  );
};

export const TableActionButtons = ({ actionConfig }: any) => {
  return (
    <div className={styles.tableActionBtnGroup}>
      {actionConfig.map(({ icon, onClick, text, className }) => {
        return (
          <StyledActionButton
            icon={icon}
            onClick={onClick}
            text={text}
            key={text}
            className={className}
          />
        );
      })}
    </div>
  );
};

export const LeftTableActionButtons = () => {
  const leftActionConfig : TableActionBtnConfigType[] = Object.values(leftTableActionBtnConfig);
  return <TableActionButtons actionConfig={leftActionConfig} />;
};

export const RightTableActionButtons = () => {
  const { setReloadTable } = usePrTableContext();
  const isFetchingRequisitionTable = useIsFetching({ queryKey: ['requisitionList'] })

  const rightActionConfig : TableActionBtnConfigType[] = Object.values({
    ...rightTableActionBtnConfig,
    ...{
      refreshTable: {
        ...rightTableActionBtnConfig.refreshTable,
        className: isFetchingRequisitionTable ? styles.spinningAnimation : "",
        onClick: () => setReloadTable((prev) => prev + 1),
      },
    },
  });

  return (
    <TableActionButtons actionConfig={rightActionConfig} />
  );
};