// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TaBJ5GINyAdmw59tEHYq .j2VM9xOptqoYCxhTv2Jq{background-color:var(--card-title-bg);height:48px;display:flex;align-items:center;padding:12px 16px;border-radius:4px 4px 0 0}.TaBJ5GINyAdmw59tEHYq .KyD3QhJycSk2te9gvq7B{border:1px solid var(--card-content-border);padding:24px 16px;border-radius:0 0 4px 4px}`, "",{"version":3,"sources":["webpack://./src/components/TitledCard/TitledCard.module.scss"],"names":[],"mappings":"AACE,4CACE,qCAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,yBAAA,CAEF,4CACE,2CAAA,CACA,iBAAA,CACA,yBAAA","sourcesContent":[".titledCard {\n  .title {\n    background-color: var(--card-title-bg);\n    height: 48px;\n    display: flex;\n    align-items: center;\n    padding: 12px 16px;\n    border-radius: 4px 4px 0 0;\n  }\n  .content {\n    border: 1px solid var(--card-content-border);\n    padding: 24px 16px;\n    border-radius: 0 0 4px 4px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titledCard": `TaBJ5GINyAdmw59tEHYq`,
	"title": `j2VM9xOptqoYCxhTv2Jq`,
	"content": `KyD3QhJycSk2te9gvq7B`
};
export default ___CSS_LOADER_EXPORT___;
