import { RequisitionListType } from "@/modules/LandingPage/components/DashboardTable/type";
// @ts-ignore
import { FilterButtonProps } from '@aiops/styleguide';
import { FilterType } from "@/modules/LandingPage/components/DashboardTable/type";
import { PRDetailsType, RuleValidationDetailType } from "@/modules/PRDetails/type";
import { MOCK_REQUSITION_DETAILS } from "@/constants/mock.constant";
import { RecommendationType, StatusType } from "@/types";

// Calculate number of days between two dates
export const getDaysOpen = (date: string) => {
    if(!date) return '-';
    const today = new Date();
    const createdDate = new Date(date);
    const diffTime = Math.abs(today.getTime() - createdDate.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}
  
// Date formatter to convert date into mm/dd/yyyy format
export const formatDate = (dateStr: any) => {
   if (!dateStr) {
     return "-";
   }
   const date = new Date(dateStr);
   return (
     (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
     "/" +
     (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
     "/" +
     date.getFullYear()
   );
};
/** 
* Takes a value as an input and returns that value as a string (if it's a 
* string or a number that isn't NaN), or "-" if the value is an empty string,
* undefined, null, or NaN.
* 
* The point is to be able to turn undefined/null/falsey/nonexistent values into
* a placeholder string to indicate that there's nothing to display for that
* value.
* 
* @param value 
* Whatever valud should be scrubbed.
*/
export function scrub(value: string | number | undefined | null) {
 const displayWhenBlank = '-'
 if (typeof value === 'number') {
   if (Number.isNaN(value)) {
     return displayWhenBlank;
   }
   return value.toString();
 }
 return value || displayWhenBlank;
}

/**
 * Returns true if input is a string.
 */
export function isString(str: unknown): str is string {
  return typeof str === 'string';
}

/**
 * Takes a number and returns it as a string formatted with a dollar sign, with
 * commas added by JS's toLocaleString function.
 * 
 * 
 * @param val 
 * The number to format.
 */
export function formatNumberAsDollarAmount(val: number | string | null | undefined) {
  if (val === null || val === undefined) {
    return scrub(val);
  }

  const asNum = isString(val) ? parseFloat(val) : val;

  // If a string is unparseable as a number, return it as itself (this covers
  // the case of input like "$1,000" which will parse as NaN).
  if (isNaN(asNum)) {
    return scrub(val);
  }

  //The next few transformations require positive integers to work as expected (Math.floor for example)
  const isNegative = asNum < 0;
  const absoluteAsNum = Math.abs(asNum)

  const beforeDecimal = Math.floor(absoluteAsNum)

  let afterDecimal: string | number = Math.round(100 * (absoluteAsNum - beforeDecimal));
  if (afterDecimal === 0) {
    afterDecimal = '00';
  } else if (afterDecimal < 10) {
    afterDecimal = `0${afterDecimal}`;
  }
  return `${isNegative ? '-' : ''}${beforeDecimal.toLocaleString()}.${afterDecimal.toString()}`;
}

// Process raw api response for landing page pr table
export const mapRequisitionListResponse = (data: any): RequisitionListType => {
    if(data === "No data found") {
      return {
        requisitions: [],
        totalResults: 0,
      }
    }
    return {
      requisitions: data?.prs.map((r: any) => {
        const currencyCode = r?.TotalCost?.Currency?.currency_code;
        return ({
        reqId: r?.requisition_id,
        erpSource: r?.source,
        daysOpen: getDaysOpen(r?.created_at), 
        numLines: r?.line_item_count,
        prDate: formatDate(r?.requisition_date),
        totalPRAmount: `${formatNumberAsDollarAmount(r?.TotalCost?.pr_amount)} ${currencyCode ? currencyCode : ""}`,
        reviewerGroup: r?.reviewer_group, 
        reviewer: r?.reviewer, 
        requester: r?.Requester?.requester, 
        status: r?.procurement_status,
        recommendation: r?.recommendation_status,
      })}),
      totalResults: data?.pr_count,
    };
}

export const getStatusQueryParam = (status: FilterButtonProps[], filterType: FilterType) => {
  let statusQueryParam = [];
  status.length > 0 && status.forEach((button) => {
    if (button?.selected) {
      // Completed procurement status is mapped to Approved and Rejected status
      if(filterType === "statusFilter" as FilterType && button.id === "Completed") {
        // statusQueryParam += "Approved,Rejected,";
        statusQueryParam.push(`"${'Approved'}"`)
        statusQueryParam.push(`"${'Rejected'}"`)
      } else {
        statusQueryParam.push(`\'${button.id}\'`);
      }
    }
  });
  
  return statusQueryParam.length > 0 ? statusQueryParam.join(`${','}`) : "";
};
// Process raw api response for dashboard analytics
export const mapDashboardAnalyticsResponse = (data: any) => {
    return {
      recommendationsByStatus: {
        approve: data?.recommendations_by_status?.Approve,
        reject: data?.recommendations_by_status?.Reject,
        review: data?.recommendations_by_status?.Review,
        total: data?.recommendations_by_status?.Approve + data?.recommendations_by_status?.Reject + data?.recommendations_by_status?.Review,
      },
      openPRsByAge: {
        '5+' : data?.open_prs_by_age?.more_than_5_days,
        '4' : data?.open_prs_by_age?.['4_days'],
        '3' : data?.open_prs_by_age?.['3_days'],
        '2' : data?.open_prs_by_age?.['2_days'],
        '1' : data?.open_prs_by_age?.['1_day'],
        '<1' : data?.open_prs_by_age?.['less_than_1_day'],
      }
    };
}

export const getCatalogMapping = (prType: string) => {
  if (prType === "non_catalog") {
    return "Non-Catalog";
  } else return "Catalog";
}

// Process raw api response for requisition details
export const mapRequisitionDetailsResponse = (data: any) : PRDetailsType => {
  const currencyCode = data?.TotalCost?.Currency?.currency_code;
    return {
        headerDetails: {
          title: data?.title,
          prDate: formatDate(data?.requisition_date),
          erpSource: data?.source,
          totalPRAmount: `${formatNumberAsDollarAmount(data?.TotalCost?.pr_amount)} ${currencyCode ? currencyCode : ""}`,
          totalLines: data?.line_item_count,
          companyCode: data?.CompanyCode?.company_code,
          requester: data?.Requester?.requester,
          submitter: data?.Preparer?.submitter,
          requisitionId: data?.requisition_id,
          // mock data for missing fields in backend response
          ruleValidations: [... MOCK_REQUSITION_DETAILS.headerDetails.ruleValidations],
        },

        lineItemsSummary: {
            totalResults: data?.line_item_count,
          // mapping needs to be done here
            lineItems: Object.entries(data?.line_items?.[0]?.line_items)?.map(([key, lineItem] : [string, any], index ) => ({
              lineNum: lineItem?.line_number,
              description: scrub(lineItem?.description),
              commodityCode: scrub(lineItem?.Description_CommonCommodityCode?.commodity_code_id),
              purchaseGroup: scrub(lineItem?.PurchaseGroup?.purchase_group_id),
              supplier: scrub(lineItem?.Supplier?.supplier_id ),
              prType: scrub(getCatalogMapping(lineItem?.pr_type)),
              unitOfMeasure: scrub(lineItem?.Description_UnitOfMeasure?.unit_of_measure),
              quantity: scrub(lineItem?.requisition_quantity),
              unitPrice: `${formatNumberAsDollarAmount(lineItem?.Description_Price?.price)} ${lineItem?.Amount?.Currency?.currency_code || ""}`,
              totalPrice: `${formatNumberAsDollarAmount(lineItem?.Amount?.line_amount)} ${lineItem?.Amount?.Currency?.currency_code || ""}`, // not part of backend response
              status: scrub(lineItem?.procurement_status) as StatusType, // confirm if correct backend response has approval requisition procurement
              recommendation: scrub(lineItem?.recommendation_status) as RecommendationType,

              // mock data for missing fields in backend response
              ruleValidations: [... MOCK_REQUSITION_DETAILS.lineItemsSummary.lineItems[index]?.ruleValidations || [] ] as RuleValidationDetailType[] ,
            })) || [],
        }
    }
}
