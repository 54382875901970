// @ts-ignore
import { StickyAppFooter, Button, CircularProgress } from '@aiops/styleguide';
import { useNavigate, useParams } from "react-router-dom";
import { HeaderRow, LineItemViewSwitcher } from './components';
import { PRDetailsContextProvider, usePRDetailsContext } from "@/modules/PRDetails/context";
import { useRequisitionDetails } from "@/hooks";
import { useEffect } from "react";


const PRDetails = () => {
    const { setPRDetails, recommendationFilter, isLineItemDetailView, setIsLineItemDetailView } = usePRDetailsContext();

    const { id } = useParams();

    /* check if this can be moved someplace else*/
    const { details: { data, isFetching, isSuccess } } = useRequisitionDetails(id, recommendationFilter);
    useEffect(() => {
      setPRDetails(data);
    }, [isSuccess]);


    /* conditional navigation using the Back button
    * From LineItemTableView, navigate back to Landing page
    * From LineItemDetailView, navigate back to LineItemTableView
    */
    const navigateTo = useNavigate();

    const navigateBack = () => {
      // If in LineItemDetailView, navigate back to LineItemTableView
      if(isLineItemDetailView) {
        setIsLineItemDetailView(false);
      }else{
        // Navigate back to the landing page
        navigateTo('/');
      }
    }

  if (isFetching) return <CircularProgress />;

    return (
        <>
          <div className="page-content">
            <HeaderRow />
            <LineItemViewSwitcher />
          </div>
          <StickyAppFooter>
            <Button variant="outlined" onClick={navigateBack}>Back</Button>
          </StickyAppFooter>
        </>
    );
}

const PRDetailsPage = () => {
    return (
      <PRDetailsContextProvider>
        <PRDetails/>
      </PRDetailsContextProvider>
    );
}

export default PRDetailsPage;