// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wVt7WaP3uLuJXAAQMfPe{height:24px;border-radius:24px;padding:4px 12px}.wVt7WaP3uLuJXAAQMfPe[class*=recommendation-Approve]{background-color:var(--color-success)}.wVt7WaP3uLuJXAAQMfPe[class*=recommendation-Reject]{background-color:var(--color-error)}.wVt7WaP3uLuJXAAQMfPe[class*=recommendation-Review]{background-color:var(--color-warning)}`, "",{"version":3,"sources":["webpack://./src/modules/PRDetails/components/RecommendationPill/RecommendationPill.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,kBAAA,CACA,gBAAA,CAEA,qDACE,qCAAA,CAEF,oDACI,mCAAA,CAEJ,oDACE,qCAAA","sourcesContent":[".recommendationPill {\n  height: 24px;\n  border-radius: 24px;\n  padding: 4px 12px;\n\n  &[class*=\"recommendation-Approve\"] {\n    background-color: var(--color-success);\n  }\n  &[class*=\"recommendation-Reject\"] {\n      background-color: var(--color-error);\n  }\n  &[class*=\"recommendation-Review\"] {\n    background-color: var(--color-warning);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recommendationPill": `wVt7WaP3uLuJXAAQMfPe`
};
export default ___CSS_LOADER_EXPORT___;
