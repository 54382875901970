export const lineItemConfig = [
  { field: 'description', title: 'Description', style: 'title' },
  { field: 'commodityCode', title: 'Commodity Code', style: '' },
  { field: 'purchaseGroup', title: 'Purchase Group', style: '' },
  { field: 'supplier', title: 'Supplier', style: 'title' },
  { field: 'prType', title: 'PR Type', style: '' },
  { field: 'unitOfMeasure', title: 'Unit of Measure', style: '' },
  { field: 'unitPrice', title: 'Unit Price', style: '' },
  { field: 'quantity', title: 'Qty', style: '' },
  { field: 'totalPrice', title: 'Total Price', style: '' },
];