import { RuleValidationDetailType } from "@/modules/PRDetails/type";
import { PRRecommendation } from "@/types";
// @ts-ignore
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button } from "@aiops/styleguide";
import styles from "./RuleValidation.module.scss";
import { useState } from "react";
import IconChevronDown from "@/assets/icons/icon-chevron-down.svg"
import { ReactSVG } from "react-svg";
import IconClose from "@/assets/icons/icon-close.svg"
import IconCheck from "@/assets/icons/icon-check.svg"
import IconAlert from "@/assets/icons/icon-alert-solid.svg";
import RulePolicyJustification from "./RulePolicyJustification";

const CollapsibleIcon = () => {
  return (
      <ReactSVG src={IconChevronDown} className={styles.expandIcon} data-altText={'collapse'}/>
  )
}

const RuleValidation = ({ isHeaderLevel, validationDetails } :
{
  isHeaderLevel?: boolean,
  validationDetails : RuleValidationDetailType
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div
      className={`${styles.ruleValidation} ${
        isHeaderLevel ? "header-validation" : "line-item-validation"
      }`}
    >
      <Accordion onChange={(e: Event, expanded: boolean) => setIsExpanded(expanded)} defaultExpanded={false}>
        <AccordionSummary expandIcon={<CollapsibleIcon />}>
          <div className={styles.ruleName}>
            <Typography variant="subheading1-normal">{validationDetails.ruleName}</Typography>
          </div>
          <div className={styles.ruleActions}>
            <Button className={styles.rejectValidation}
                variant={validationDetails?.recommendation === PRRecommendation.Reject ? 'filled' : 'outlined'}>
              <ReactSVG src={IconClose} data-altText={'reject'} />
              Reject
            </Button>
            <Button className={styles.approveValidation}
                variant={validationDetails?.recommendation === PRRecommendation.Approve ? 'filled' : 'outlined'}>
              <ReactSVG src={IconCheck} data-altText={'approve'}/>
              Approve
            </Button>
            <div className={styles.ruleValidationWarning}>
              {
                  validationDetails?.warnings?.length > 0 && (
                      <ReactSVG src={IconAlert} data-altText={'warning'} />
                  )
              }
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <RulePolicyJustification policy={validationDetails?.policy} justification={validationDetails?.justification}/>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default RuleValidation;