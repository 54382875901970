// @ts-ignore
import { FilterButtonProps } from '@aiops/styleguide';
import { PRTableContext } from "./prTable.context";
import { useMemo, useState } from "react";
import { RecommendationFilterButtons, StatusFilterButtons } from "@/constants/filterBar.constant";
import { DEFAULT_RES_PER_PAGE } from "@/config/defaultTableConfigs";
import { SortByType } from '../../components/DashboardTable/type';

export const PRTableContextProvider = ({ children }) => {

    const [statusFilter, setStatusFilter] = useState<FilterButtonProps>(StatusFilterButtons);
    const [recommendationFilter, setRecommendationFilter] = useState<FilterButtonProps>(RecommendationFilterButtons);
    const [searchFilter, setSearchFilter] = useState<string>("");
    const [selectedReqIds, setSelectedReqIds] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_RES_PER_PAGE);
    const [sortBy, setSortBy] = useState<SortByType>({ column: "", order: "" });
    const [reloadTable, setReloadTable] = useState<number>(0);

    const prTableContext = useMemo(() => ({
        statusFilter,
        setStatusFilter,
        recommendationFilter,
        setRecommendationFilter,
        searchFilter,
        setSearchFilter,
        selectedReqIds,
        setSelectedReqIds,
        currentPage,
        setCurrentPage,
        rowsPerPage,
        setRowsPerPage,
        sortBy,
        setSortBy,
        reloadTable,
        setReloadTable
    }), [statusFilter, recommendationFilter, searchFilter,
        selectedReqIds, currentPage, rowsPerPage, sortBy, setSortBy, reloadTable]);

    return <PRTableContext.Provider value={prTableContext}>{children}</PRTableContext.Provider>;
};