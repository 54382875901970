export const lineItemTableConfig = [
  { field: "statusTooltip" },
  { field: "lineNum" },
  { field: "description" },
  { field: "commodityCode" },
  { field: "purchaseGroup" },
  { field: "supplier" },
  { field: "prType" },
  { field: "unitOfMeasure" },
  { field: "unitPrice" },
  { field: "quantity" },
  { field: "totalPrice" },
  { field: "recommendationContent" }
];

export const headerCells = {
  statusTooltip: { title: '', key: 1, styleObj: { maxWidth: "40px" } },
  lineNum: { title: 'Line #', key: 2, styleObj: { maxWidth: "40px" } },
  description: { title: 'Description', key: 3, styleObj: { minWidth: "145px" } },
  commodityCode: { title: 'Commodity Code', key: 4 },
  purchaseGroup: { title: 'Purchase Group', key: 5 },
  supplier: { title: 'Supplier', key: 6 },
  prType: { title: 'PR Type', key: 7 },
  unitOfMeasure: { title: 'Unit of Measure', key: 8, styleObj: { maxWidth: "70px" } },
  unitPrice: { title: 'Unit Price', key: 9 },
  quantity: { title: 'Qty', key: 10, styleObj: { maxWidth: "40px" } },
  totalPrice: { title: 'Total Price', key: 11 },
  recommendationContent: { title: 'Recommendation', key: 12 },
}