import axios from "axios";
import { ENDPOINTS } from "@/constants/endPoints";

const axiosInstance = axios.create({});

const setBaseUrl = (baseURL: string | undefined): void => {
  axiosInstance.defaults.baseURL = `${baseURL}/${ENDPOINTS.PREFIX}`;
};

const setAuthToken = (authToken: string): void => {
  axiosInstance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${authToken}`;
};

export { axiosInstance, setBaseUrl, setAuthToken };
