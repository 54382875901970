// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pYU5P2PLHf5MXjTRgwhV div[class*=MuiTooltip-tooltip]{background-color:var(--white);color:var(--base-700);filter:drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.15));font-size:14px;line-height:16px;font-weight:normal;padding:8px 16px}.pYU5P2PLHf5MXjTRgwhV div[class*=MuiTooltip-arrow]{color:var(--white)}.pYU5P2PLHf5MXjTRgwhV span[class*=MuiTooltip-arrow]{color:var(--white)}`, "",{"version":3,"sources":["webpack://./src/components/StyledTooltip/StyledTooltip.module.scss"],"names":[],"mappings":"AACE,qDACE,6BAAA,CACA,qBAAA,CACA,mDAAA,CACA,cAAA,CACA,gBAAA,CACA,kBAAA,CACA,gBAAA,CAGF,mDACE,kBAAA,CAEF,oDACE,kBAAA","sourcesContent":[".tooltipPopper{\n  div[class*=\"MuiTooltip-tooltip\"] {\n    background-color: var(--white);\n    color: var(--base-700);\n    filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.15));\n    font-size: 14px;\n    line-height: 16px;\n    font-weight: normal;\n    padding: 8px 16px;\n  }\n\n  div[class*=\"MuiTooltip-arrow\"] {\n    color: var(--white);\n  }\n  span[class*=\"MuiTooltip-arrow\"] {\n    color: var(--white);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipPopper": `pYU5P2PLHf5MXjTRgwhV`
};
export default ___CSS_LOADER_EXPORT___;
