import { useParams } from "react-router-dom";
//@ts-ignore
import { Typography } from "@aiops/styleguide";
import styles from "./HeaderRow.module.scss";
import { DetailsOverview } from "@/modules/PRDetails/components";
import { usePRDetailsContext } from "@/modules/PRDetails/context";
import { headerConfig } from "./config";

const ValidationProgress = () => {
  const MOCK_PROGRESS = 50;

  return (
    <div className={styles.progressGroup}>
      <div className="textRow">
        <Typography variant="caption1c" className="secondaryText">
          Validation Status (mocked)
        </Typography>
        <Typography variant="caption1b">
          {`${MOCK_PROGRESS}%`}
        </Typography>
      </div>
      <div className="progress-bar">
        <div style={{ width: `${MOCK_PROGRESS}%` }} className="progress-bar-indicator" data-testid="test-progress-bar" />
      </div>
    </div>
  )
}

export const HeaderRow = () => {

  const { prDetails } = usePRDetailsContext();

  return (
    <div className={styles.headerRow}>
      <div className={styles.heading}>
        <Typography variant="heading3">{prDetails?.headerDetails?.requisitionId}</Typography>
        <ValidationProgress />
      </div>
      <DetailsOverview
          configMap={headerConfig}
          details={prDetails?.headerDetails}
      />
    </div>
  )
};