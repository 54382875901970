import { useMemo, useState } from "react";
import { PRDetailsContext } from "./PRDetails.context";
import { RecommendationFilterButtons } from "@/constants/filterBar.constant";
// @ts-ignore
import { FilterButtonProps } from '@aiops/styleguide';
import { PRDetailsType } from "@/modules/PRDetails/type";

export const PRDetailsContextProvider = ({ children }) => {
    const [prDetails, setPRDetails] = useState<PRDetailsType>({});

    /* switch between PR details tab and history tab. By default, show Details tab */
    const [tabSelected, setTabSelected] = useState(0);

    /* switch between line item detail vs line item summary table view. By default, show line item summary table */
    const [isLineItemDetailView, setIsLineItemDetailView] = useState(false);
    const [selectedLineItem, setSelectedLineItem] = useState({});

    /* Filter bar config for recommendation */
    const [recommendationFilter, setRecommendationFilter] = useState<FilterButtonProps[]>(RecommendationFilterButtons);

    const prDetailsContext = useMemo(() => ({
        prDetails,
        setPRDetails,
        isLineItemDetailView,
        setIsLineItemDetailView,
        tabSelected,
        setTabSelected,
        selectedLineItem,
        setSelectedLineItem,
        recommendationFilter,
        setRecommendationFilter,
    }), [prDetails, isLineItemDetailView, tabSelected, selectedLineItem, recommendationFilter]);

    return (
        <PRDetailsContext.Provider value={prDetailsContext}>
            {children}
        </PRDetailsContext.Provider>
    );
};

