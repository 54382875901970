// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.M7NRxKDnD4fhSU4buWCq[class*=status-indicator-approved] path{fill:var(--approved-status)}.M7NRxKDnD4fhSU4buWCq[class*=status-indicator-rejected] path{fill:var(--rejected-status)}.M7NRxKDnD4fhSU4buWCq[class*=status-indicator-pending] path{fill:var(--pending-status)}`, "",{"version":3,"sources":["webpack://./src/modules/PRDetails/components/PRStatusIndicator/PRStatusIndicator.module.scss"],"names":[],"mappings":"AACI,6DACI,2BAAA,CAEJ,6DACI,2BAAA,CAEJ,4DACI,0BAAA","sourcesContent":[".statusIndicator {\n    &[class*=\"status-indicator-approved\"] path {\n        fill: var(--approved-status);\n    }\n    &[class*=\"status-indicator-rejected\"] path {\n        fill: var(--rejected-status);\n    }\n    &[class*=\"status-indicator-pending\"] path {\n        fill: var(--pending-status);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusIndicator": `M7NRxKDnD4fhSU4buWCq`
};
export default ___CSS_LOADER_EXPORT___;
