// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gfBEvN9DT2O7icJEq4En{display:flex;justify-content:flex-start;align-items:center;margin-bottom:8px;max-width:256px}`, "",{"version":3,"sources":["webpack://./src/modules/PRDetails/components/LineItemWidget/LineItemWidget.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,0BAAA,CACA,kBAAA,CACA,iBAAA,CACA,eAAA","sourcesContent":[".lineItemWidget {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  margin-bottom: 8px;\n  max-width: 256px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lineItemWidget": `gfBEvN9DT2O7icJEq4En`
};
export default ___CSS_LOADER_EXPORT___;
