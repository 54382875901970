// @ts-ignore
import { AppWrapper, AuthProvider, ToastProvider, ModalProvider, AuthCheckWrap } from "@aiops/styleguide";
import Router from "@/router/Router";
import mainStyles from '@/assets/styles/procurement.module.scss';
import './assets/styles/theme-aiops.module.scss';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ProcurementContextProvider } from "@/context";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
});

export default function Root(props: { app: { path: any; appId: any; endpoints: { KMSEndpointBaseUrl: string } }; }) {
  // Wrap the whole app in the styleguide's AppWrapper component
  // Remember not to use Material UI directly - import all material ui
  // components through the styleguide

  //These props are passed from the root application
  const PATH = props?.app.path
  const APP_ID = props?.app.appId
  const KMSEndpointBaseUrl = props?.app.endpoints?.KMSEndpointBaseUrl

  if (!PATH || !APP_ID) {
    throw new Error(`Expected appId and path in props: ${props}`);
  }

  const checkPerms = (perms: any) => {
    return !!perms?.apps[APP_ID];
  }

  return (
    <AppWrapper>
      <AuthProvider
        checkPermissions={checkPerms}
      >
        <ProcurementContextProvider endpointBaseUrl={KMSEndpointBaseUrl}>
          <QueryClientProvider client={queryClient}>
            <ToastProvider>
              <ModalProvider>
                <AuthCheckWrap
                    renderWithAuthCheck={AppComponent}
                />
              </ModalProvider>
            </ToastProvider>
          </QueryClientProvider>
        </ProcurementContextProvider>
      </AuthProvider>
    </AppWrapper>
  )
}

// Add app router here
const AppComponent = () => {

  return (
      <div className={mainStyles.procurementExecution}>
        <Router />
      </div>
  )
}
