// @ts-ignore
import { Typography } from "@aiops/styleguide";
import { usePRDetailsContext } from "@/modules/PRDetails/context";
import styles from './LineItemDetail.module.scss';
import { TitledCard } from "@/components";
import { DetailsOverview, PRStatusIndicator, RecommendationPill, RuleValidation } from "@/modules/PRDetails/components";
import { lineItemConfig } from "@/modules/PRDetails/components/LineItemDetail/config";

const LineItemDetail = () => {
  const { selectedLineItem } = usePRDetailsContext();

  return (
    <TitledCard
      className={styles.lineItemDetail}
      titleComponent={
        <div className={styles.lineItemDetailHeader}>
          <div>
            <Typography variant="heading7">
              Line {selectedLineItem.lineNum}
            </Typography>
            <PRStatusIndicator status={selectedLineItem?.status} />
          </div>
          <div>
            <Typography variant="caption1b">
              Recommendation:
            </Typography>
            <RecommendationPill recommendation={selectedLineItem?.recommendation} />
          </div>
        </div>
      }
    >
      <>
        <DetailsOverview
            configMap={lineItemConfig}
            details={selectedLineItem}
        />
        {
          selectedLineItem?.ruleValidations?.map((ruleValidation, index) => (
            <RuleValidation
                key={index}
                validationDetails={ruleValidation}
            />
          ))
        }
      </>
    </TitledCard>
  );
}

export default LineItemDetail;