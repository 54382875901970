import iconAssign from "@/assets/icons/icon-assign.svg";
import iconUsers from "@/assets/icons/icon-users.svg";
import iconApprove from "@/assets/icons/icon-check.svg";
import iconReject from "@/assets/icons/icon-close.svg";
import iconHold from "@/assets/icons/icon-hold.svg";
import iconExport from "@/assets/icons/icon-export.svg";
import iconRefresh from "@/assets/icons/icon-refresh.svg";
import iconSettings from "@/assets/icons/icon-settings.svg";

export type TableActionBtnConfigType = { icon: string, text: string, onClick?: () => void, className?: string, disabled?: boolean };

export const leftTableActionBtnConfig : Record<string, TableActionBtnConfigType> = {
  assign : { icon: iconAssign, onClick: () => { }, text: "Assign" },
  assignUser: { icon: iconUsers, onClick: () => { }, text: "Assign User"},
  approvePR: { icon: iconApprove, onClick: () => { }, text: "Approve" },
  rejectPR:  { icon: iconReject, onClick: () => { }, text: "Reject" },
  holdReleasePR: { icon: iconHold, onClick: () => { }, text: "Hold/Release" },
  export: { icon: iconExport, onClick: () => { }, text: "Export" },
}


export const rightTableActionBtnConfig : Record<string, TableActionBtnConfigType> = {
  refreshTable: { icon: iconRefresh, text: "Refresh" },
  settings: { icon: iconSettings, text: "Settings" }
};