// @ts-ignore
import { Typography } from "@aiops/styleguide";
import styles from './TitledCard.module.scss';
import { ReactNode } from "react";

type TitledCardProps = {
    title?: string;
    titleComponent?: ReactNode;
    children?: ReactNode;
    className?: string;
};

const TitledCard = ({ title, titleComponent, children, className } : TitledCardProps) => (
    <div className={`${styles.titledCard} ${className}`}>
        <div className={styles.title}>
            {/*{titleIcon && <img src={titleIcon.src} alt={title} className={titleIcon.className}/>}*/}
            { title && <Typography variant="subheading1">{title.toUpperCase()}</Typography> }
            { titleComponent }
        </div>
        <div className={styles.content}>
          {children}
        </div>
    </div>
);

            export default TitledCard;