// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KdhvHnld6z7P2cDBIbRl{display:flex;align-items:center;justify-content:flex-end;flex-direction:row;gap:8px}.mwq3VK9T5cKoPxBsXQXw{display:flex;align-items:center;justify-content:space-between}`, "",{"version":3,"sources":["webpack://./src/modules/LandingPage/LandingPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,kBAAA,CACA,OAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA","sourcesContent":[".rightGroup {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  flex-direction: row;\n  gap: 8px;\n}\n\n.titleRow {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rightGroup": `KdhvHnld6z7P2cDBIbRl`,
	"titleRow": `mwq3VK9T5cKoPxBsXQXw`
};
export default ___CSS_LOADER_EXPORT___;
