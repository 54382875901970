import { DETAILS } from "@/router/TabRoutes.constant";
// @ts-ignore
import { Typography } from "@aiops/styleguide";
import { useNavigate } from "react-router-dom";
import styles from './DashboardTable.module.scss';
import { useProcurementContext } from "@/context";
import ThresholdFlag from "@/assets/icons/icon-flag.svg";
import { StyledTooltip } from "@/components";
import { PRRecommendation } from "@/types";

type LinkTextProps = {
  reqNumber: string;
  source: string;
};

export const LinkText = ({ reqNumber, source }: LinkTextProps) => {
  const nav = useNavigate();
  return (
    <div className={styles.prLinkButton}>
      <button
        data-testid="link-text-btn"
        className={styles.linkButton}
        // onClick={() => nav(`/${DETAILS}/${reqNumber}_${source}`)}
      >
        <Typography variant="caption1c">{reqNumber}</Typography>
      </button>
    </div>
  )
}

export const FlagDaysOpen = ({ daysOpen, reqNumber }) => {
    const { configurations: {
        daysOpenThreshold
    } } = useProcurementContext();

    return (
        <div className={styles.daysOpen}>
          <Typography variant="caption1c">{daysOpen}</Typography>
          { daysOpen > daysOpenThreshold &&
              <StyledTooltip
                  title={'Overdue PR'}
                  key={`tooltip-${reqNumber}`}
                  placement="top"
                  arrow
              >
                  <img src={ThresholdFlag} alt="above threshold"/>
              </StyledTooltip>
          }
        </div>
    )
};

export  const getNewSortOrder = (sortBy, field) => {
  // daysOpen backend "descending" sort returns the youngest PRs first so sort order is reversed here to return the oldest PRs on initial click (asc in BE call) and show the descending arrow (down) when oldest PRs are first results
  if (field === "daysOpen" && sortBy.column !== field) {
    return "asc";
  } else {
    if (sortBy.column !== field) {
     return "desc";
    } else if (sortBy.order === "asc") { 
       return "desc";
    } else return "asc";
  }
 };