import styles from './PRStatusIndicator.module.scss';
import { ReactSVG } from "react-svg";
import IconCheckCircleSolid from '@/assets/icons/icon-check-circle-solid.svg';
import IconOffTrackSolid from '@/assets/icons/icon-off-track-solid.svg';
import { StyledTooltip } from "@/components";

const PRStatusIndicator = ({ status, id }: { status: string, id?: any }) => {

    const renderStatusIndicator = (status: string) => {
        switch (status) {
            case "approved":
            case "rejected":
                return <ReactSVG src={IconCheckCircleSolid} />;
            case "pending":
                return <ReactSVG src={IconOffTrackSolid} />;
            default:
                return <div>unknown</div>;
        }
    }

    return (
        <div className={`${styles.statusIndicator} status-indicator-${status.toLowerCase()}`}>
            <StyledTooltip title={status} placement="top" arrow key={`tooltip-${status}-${id}`}>
                <div>{renderStatusIndicator(status.toLowerCase())}</div>
            </StyledTooltip>
        </div>
    );
}

export default PRStatusIndicator;