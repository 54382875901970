import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import {
    LandingPage,
    PRDetailsPage,
    ProcurementExecutionPage,
    ProcurementSettings
} from "@/modules";


const Router = () => {
    return (
        <BrowserRouter basename={'procurement-execution'}>
            <Routes>
                <Route element={<ProcurementExecutionPage />}>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/details/:id" element={<PRDetailsPage />} />
                    <Route path="/settings" element={<ProcurementSettings />} />
                    <Route path="" element={<Navigate to={"/"} />} />
                    <Route path="*" element={<Navigate to={"/"} />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default Router;