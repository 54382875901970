import { useQuery } from "@tanstack/react-query";
import { getDashboardAnalytics } from "@/services/requisition.requests";

export const useDashboardAnalytics = () => {
  return useQuery({
    queryKey: ["dashboardAnalytics"],
    queryFn: () => getDashboardAnalytics(),
    refetchOnWindowFocus: false,
    retry: false
  });
};