import styles from './LineItemViewSwitcher.module.scss';
import { LineItemTable, LineItemDetail, LineItemWidget, RuleValidation } from "@/modules/PRDetails/components";
// @ts-ignore
import { FilterBar, FilterButtonProps, TabPanelView, TabSwitcher } from '@aiops/styleguide';
import { usePRDetailsContext } from "@/modules/PRDetails/context";

const LineItemViewSwitcher = () => {
  /* get state and set methods from context */
  const {
    prDetails,
    isLineItemDetailView, setIsLineItemDetailView,
    tabSelected, setTabSelected,
    selectedLineItem, setSelectedLineItem,
    recommendationFilter, setRecommendationFilter
  } = usePRDetailsContext();

  /* Line item selection handler : switches between line item - summary table vs detail view */
  const lineItemSelectionHandler = (lineItem: any) => {
    setIsLineItemDetailView(true);
    setSelectedLineItem(lineItem);
  }

  const tabConfig = {
    routes: [
      {
        component: <LineItemTable onLineItemSelection={lineItemSelectionHandler}/>,
        view: 'Details',
        order: 0
      },
      {
        component: <div>History Placeholder</div>,
        view: 'History',
        order: 1
      },
    ],
    defaultSelected: 0
  };

  const handleRecommendationFilterChange = (newState: FilterButtonProps[]) => {
    setRecommendationFilter(newState);
  }

  const renderLineItemDetailView = () => {
    return (
      <>
        {selectedLineItem && (
          <>
            { prDetails?.headerDetails?.totalLines > 1 && <LineItemWidget />}
            <LineItemDetail />
            {prDetails.headerDetails?.ruleValidations?.length > 0 && (
              <div className={styles.headerRuleValidation}>
                {prDetails.headerDetails?.ruleValidations?.map(
                  (ruleValidation, index) => (
                    <RuleValidation
                      key={index}
                      validationDetails={ruleValidation}
                      isHeaderLevel={true}
                    />
                  )
                )}
              </div>
            )}
          </>
        )}
      </>
    );
  };

  const renderLineItemTableView = () => {
    return (
      <>
        <div className={styles.lineItemTableViewHeader}>
          <TabSwitcher
            selected={tabSelected}
            onTabSelectionChange={(e, newSelection) =>
              setTabSelected(newSelection)
            }
            routes={tabConfig.routes}
          />
          {
            tabSelected === 0 && (
              <FilterBar
                filterButtons={recommendationFilter}
                onChange={handleRecommendationFilterChange}
              />
            )
          }
        </div>
        <TabPanelView routes={tabConfig.routes} selected={tabSelected} />
      </>
    );
  };

  return (
    <>
      {isLineItemDetailView && renderLineItemDetailView()}
      {!isLineItemDetailView && renderLineItemTableView()}
    </>
  );
}

export default LineItemViewSwitcher;
