// @ts-ignore
import { Chart, ChartType, CHART_THEME, GRAPHIC_COLORS, BarChartConfig } from "@aiops/charting";

export const openPrsAging: BarChartConfig = {
  xAxes: [{
    label: 'Open PRs Aging',
    key: 'openPrsAging',
    mapping: [
      { label: '> 5 Days', key: '5+', textColor: CHART_THEME.defaultLight, color: GRAPHIC_COLORS.warm600 },
      { label: '4 Days', key: '4', textColor: CHART_THEME.defaultLight, color: GRAPHIC_COLORS.warm700 },
      { label: '3 Days', key: '3', textColor: CHART_THEME.defaultLight, color: GRAPHIC_COLORS.cool800 },
      { label: '2 Days', key: '2', textColor: CHART_THEME.defaultLight, color: GRAPHIC_COLORS.cool500 },
      { label: '1 Day', key: '1', textColor: CHART_THEME.defaultLight, color: GRAPHIC_COLORS.cool400 },
      { label: '< 1 Day', key: '<1', textColor: CHART_THEME.defaultLight, color: GRAPHIC_COLORS.cool300 }],
    isColorAxis: true
  }],
  yAxes: [{
    label: 'PRs',
    key: '',
    isColorAxis: false
  }],
  barOptions: {
    xAxisStacked: false,
    yAxisStacked: false,
    barThickness: 20,
  },
  legendOptions: {
    display: false,
    label: '',
    axisKey: '',
    axisType: 'Y'
  },
  tooltipOptions: {
    enabled: false,
  },
  dataLabels: {
    display: true,
    font: {
      size: 12,
      lineHeight: '16px',
      family: 'Open Sans',
    },
    color: CHART_THEME.defaultLight,
    // aligns the data label to the bottom of the bar
    anchor: 'start',
    align: 'top',
    offset: -4
  },
};


export const BarChart = ({ isLoading, chartData }) => {
  return (
    <div className="barChart">
      <Chart
        title={'Open PRs Aging'}
        type={ChartType.Bar}
        data={chartData}
        config={openPrsAging}
        {...{ isLoading }}
      />
    </div>
  )
}