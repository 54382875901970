
import { DEFAULT_RES_PER_PAGE, DEFAULT_RES_PER_PAGE_OPTS } from "@/config";
// @ts-ignore
import { Table, TableFooterPaginationProps } from "@aiops/styleguide";
import { useState } from "react";
import { headerCells, lineItemTableConfig } from "./config";
import { usePRDetailsContext } from "@/modules/PRDetails/context";
import classes from "./LineItemTable.module.scss";
import { PRStatusIndicator } from "@/modules/PRDetails/components";
import { RecommendationContent } from "@/components";

type LineItemTableProps = {
  onLineItemSelection: (lineItem: any) => void;
}

export const LineItemTable = ({ onLineItemSelection } : LineItemTableProps) => {

  const { prDetails : {
    lineItemsSummary : {
        totalResults,
        lineItems
    },
  } = {
    lineItemsSummary: {
      totalResults: 0,
      lineItems: []
    }
  }} = usePRDetailsContext();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_RES_PER_PAGE);

  const pagination: TableFooterPaginationProps = {
    currentPage,
    rowsPerPage,
    rowsPerPageOptions: DEFAULT_RES_PER_PAGE_OPTS,
    totalResults,
    onNextPage: () => {
      const maxPageNum = Math.ceil(totalResults / rowsPerPage) - 1;
      setCurrentPage(Math.min(currentPage + 1, maxPageNum));
    },
    onPreviousPage: () => setCurrentPage(Math.max(currentPage - 1, 0)),
    onSelectRowsPerPage: (r: number) => {
      // When changing the number of results per page, dump the user back to the
      // first page.
      setCurrentPage(0);
      setRowsPerPage(r);
    }
  }
  return (
    <div className={classes.lineItemTable}>
      <Table
        tableIsLoading={false}
        columnKeys={lineItemTableConfig.map((col) => col.field)}
        headerCells={headerCells}
        rows={lineItems.map((item) => ({
          ...item,
          statusTooltip: <PRStatusIndicator status={item?.status} id={item?.lineNum} />,
          recommendationContent: <RecommendationContent recommendation={item?.recommendation} />
        }))}
        rowProps={{
          rowIsClickable: true,
          onRowClick: (row: any) => {
            onLineItemSelection(row);
          },
          cellClassNames: {
            description: "lineDescription",
            statusTooltip: "status",
            lineNum: "lineNum",
            quantity: "qty",
            unitOfMeasure: "uom"
          }
        }}
        pagination={pagination}
      />
    </div>
  )
}