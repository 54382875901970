import { RecommendationType, StatusType } from "@/types";

export const MOCK_REQUSITION_DETAILS = {
  headerDetails: {
    erpSource: "SAP",
    prDate: "05/25/2024",
    requester: "Simone Biles",
    title: "Laptop unit with a very long name goes here so that it can all fit very well and so we now can limit it to two lines in here",
    totalAmount: 10000,
    totalLines: 2,
    code: "123456",
    submitter: "Aly Raisman",
    ruleValidations: [
      {
        ruleName: "Single Supplier (mocked)",
        policy: "Policy 1",
        justification: "Justification 1",
        recommendation: "Approve" as RecommendationType,
        warnings: [
          {
            warning: "Warning 1",
            description: "Description 1"
          },
          {
            warning: "Warning 2",
            description: "Description 2"
          }
        ]
      },
      {
        ruleName: "Single Currency (mocked)",
        policy: "Policy 2",
        justification: "Justification 2",
        recommendation: "Approve" as RecommendationType,
        warnings: []
      }
    ]
  },
  lineItemsSummary: {
    totalResults: 2,
    lineItems: [
      {
        lineNum: 1,
        description: "Laptop",
        commodityCode: "123456",
        purchaseGroup: "group1",
        supplier: "Dell",
        prType: "Catalog",
        unitOfMeasure: "Each",
        quantity: 2,
        unitPrice: 5000,
        totalPrice: 10000,
        status: "Pending" as StatusType,
        recommendation: "Approve" as RecommendationType,
        ruleValidations: [
          {
            ruleName: "Rule 1 (mocked)",
            policy: "Policy 1",
            justification: "Justification 1",
            recommendation: "Reject" as RecommendationType,
            warnings: []
          },
          {
            ruleName: "Rule 2 (mocked)",
            policy: "Policy 2",
            justification: "Justification 2",
            recommendation: "Approve" as RecommendationType,
            warnings: [
              {
                warning: "Warning 1",
                description: "Description 1"
              },
              {
                warning: "Warning 2",
                description: "Description 2"
              }
            ]
          }
        ]
      },
      {
        lineNum: 2,
        description: "Mouse",
        commodityCode: "123457",
        purchaseGroup: "group2",
        supplier: "Logitech",
        prType: "Non-Catalog",
        unitOfMeasure: "Each",
        quantity: 1,
        unitPrice: 50,
        totalPrice: 50,
        status: "Approved" as StatusType,
        recommendation: "Approve" as RecommendationType,
      },
    ]
  }
};