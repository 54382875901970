// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Nlpl7DXDVTqD6FztqhIP{display:flex;gap:8px}.Nlpl7DXDVTqD6FztqhIP img[class*=icon]{width:16px;height:16px}@keyframes rZ6lt4DwD7eURd6KW48d{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}.Nlpl7DXDVTqD6FztqhIP ._p5xTYuO9FseAAtkbHzN{animation:rZ6lt4DwD7eURd6KW48d 1s linear infinite}`, "",{"version":3,"sources":["webpack://./src/modules/LandingPage/components/TableActionButtons/TableActionButtons.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,OAAA,CACA,uCACE,UAAA,CACA,WAAA,CAIF,gCACE,GACE,sBAAA,CAEF,KACE,wBAAA,CAAA,CAIJ,4CACE,iDAAA","sourcesContent":[".tableActionBtnGroup {\n  display: flex;\n  gap: 8px;\n  img[class*='icon'] {\n    width: 16px;\n    height: 16px;\n  }\n\n  // animations : need to find an approach to move this to global styles\n  @keyframes spin {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n\n  .spinningAnimation {\n    animation: spin 1s linear infinite;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableActionBtnGroup": `Nlpl7DXDVTqD6FztqhIP`,
	"spinningAnimation": `_p5xTYuO9FseAAtkbHzN`,
	"spin": `rZ6lt4DwD7eURd6KW48d`
};
export default ___CSS_LOADER_EXPORT___;
