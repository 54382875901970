// @ts-ignore
import { Typography } from '@aiops/styleguide';
import {
    TableFilter,
    DashboardTable,
    DashboardCharts,
    SearchBar,
    LeftTableActionButtons,
    RightTableActionButtons
} from './components';
import { TableActionsWrapper } from "@/components";
import {PRTableContextProvider} from "@/modules/LandingPage/context/PRTableContext";
import styles from './LandingPage.module.scss';

const LandingPage = () => {
    return (
        <PRTableContextProvider>
            <div className="page-content">
                <div className={styles.titleRow}>
                    <Typography variant="heading2-titles">Autonomous Procurement</Typography>
                    <SearchBar />
                </div>
                <br />
                <DashboardCharts />
                <br />
                <TableActionsWrapper>
                    <div>
                        <LeftTableActionButtons />
                    </div>
                    <div className={styles.rightGroup}>
                        <TableFilter />
                        <RightTableActionButtons /> 
                    </div>
                </TableActionsWrapper>
                <DashboardTable />
            </div>
        </PRTableContextProvider>
    );
}

export default LandingPage;