// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ggOqH62tOgddRnNmYsuo{box-sizing:border-box;display:flex;align-items:center;justify-content:flex-start;width:365px;height:32px;background:var(--table-row-bg);border:1px solid var(--sad-lilac);border-radius:4px}.ggOqH62tOgddRnNmYsuo .p1e5CP3fSVC2JA3SeCO1{height:15px;width:15px;margin:0 10px}.ggOqH62tOgddRnNmYsuo .Igpwo0k2VTdMta_16bNT{flex:1}.ggOqH62tOgddRnNmYsuo .Igpwo0k2VTdMta_16bNT .eEHZN8jI3qRA861emjuT{all:unset;width:100%;box-sizing:border-box;height:24px;color:var(--write-here)}.ggOqH62tOgddRnNmYsuo .Igpwo0k2VTdMta_16bNT .eEHZN8jI3qRA861emjuT::placeholder{color:var(--sad-lilac)}`, "",{"version":3,"sources":["webpack://./src/modules/LandingPage/components/SearchBar/SearchBar.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,WAAA,CACA,WAAA,CACA,8BAAA,CACA,iCAAA,CACA,iBAAA,CAEA,4CACI,WAAA,CACA,UAAA,CACA,aAAA,CAGJ,4CACI,MAAA,CAEA,kEACI,SAAA,CACA,UAAA,CACA,qBAAA,CACA,WAAA,CACA,uBAAA,CAEJ,+EACI,sBAAA","sourcesContent":[".searchBarContainer {\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  width: 365px;\n  height: 32px;\n  background: var(--table-row-bg);\n  border: 1px solid var(--sad-lilac);\n  border-radius: 4px;\n\n  .searchBarIcon {\n      height: 15px;\n      width: 15px;\n      margin: 0 10px;\n  }\n\n  .searchBarForm {\n      flex: 1;\n\n      .searchBarInput {\n          all: unset;\n          width: 100%;\n          box-sizing: border-box;\n          height: 24px;\n          color: var(--write-here);\n      }\n      .searchBarInput::placeholder {\n          color: var(--sad-lilac);\n      }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBarContainer": `ggOqH62tOgddRnNmYsuo`,
	"searchBarIcon": `p1e5CP3fSVC2JA3SeCO1`,
	"searchBarForm": `Igpwo0k2VTdMta_16bNT`,
	"searchBarInput": `eEHZN8jI3qRA861emjuT`
};
export default ___CSS_LOADER_EXPORT___;
