// @ts-ignore
import { ChartCard } from "@aiops/charting";
// @ts-ignore
import { useToast } from "@aiops/styleguide";
import { BarChart } from "./BarChart";
import { DoughnutChart } from "./DoughnutChart";
import styles from "./DashboardCharts.module.scss";
import { useDashboardAnalytics } from "@/hooks/useDashboardAnalytics";
import { useEffect } from "react";

export const DashboardCharts = () => {
  const { setCurrentToast } = useToast();

  const errorHandler = (error: any) => {
    setCurrentToast({
      message: `${error.message} : Fetching dashboard analytics`,
      severity: "error",
    });
  }

  const { data, isLoading, error } = useDashboardAnalytics();

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  return (
    <div className={styles.dashboardCharts}>
      <ChartCard>
        <div className="chart-wrapper">
          <DoughnutChart chartData={data?.recommendationsByStatus} isLoading={isLoading}/>
        </div>
        <div className="chart-wrapper">
          <BarChart chartData={data?.openPRsByAge} isLoading={isLoading} />
        </div>
      </ChartCard>
    </div>
  );
}