import ProcurementExecution from "./ProcurementExecution";

const ProcurementExecutionPage = () => {
    return(
        <div className="full-page">
            <ProcurementExecution />
        </div>
    )
}

export default ProcurementExecutionPage;