// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dXP22m9jJQ04GSUPcux7 *[class*=full-page]{display:flex;flex-direction:column;align-items:center;justify-content:space-between;width:100%;min-height:calc(100vh - var(--header-height));padding:0 40px}.dXP22m9jJQ04GSUPcux7 *[class*=full-page] *[class*=page-content]{display:flex;flex-direction:column;align-items:stretch;justify-content:flex-start;width:100%;margin-top:40px}.dXP22m9jJQ04GSUPcux7 button[class*=approve-filter]{color:var(--color-success)}.dXP22m9jJQ04GSUPcux7 button[class*=reject-filter]{color:var(--color-error)}.dXP22m9jJQ04GSUPcux7 button[class*=review-filter]{color:var(--color-warning)}`, "",{"version":3,"sources":["webpack://./src/assets/styles/procurement.module.scss"],"names":[],"mappings":"AAGE,0CACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,6BAAA,CACA,UAAA,CACA,6CAAA,CACA,cAAA,CAEA,iEACE,YAAA,CACA,qBAAA,CACA,mBAAA,CACA,0BAAA,CACA,UAAA,CACA,eAAA,CAKJ,oDACE,0BAAA,CAGF,mDACE,wBAAA,CAGF,mDACE,0BAAA","sourcesContent":["/** should have only global styles specific for Procurement **/\n\n.procurementExecution {\n  *[class*=\"full-page\"] {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    min-height: calc(100vh - var(--header-height));\n    padding: 0 40px;\n\n    *[class*=\"page-content\"] {\n      display: flex;\n      flex-direction: column;\n      align-items: stretch;\n      justify-content: flex-start;\n      width: 100%;\n      margin-top: 40px;\n    }\n  }\n\n  //recommendation filter bar styles\n  button[class*=\"approve-filter\"] {\n    color: var(--color-success);\n  }\n\n  button[class*=\"reject-filter\"] {\n    color: var(--color-error);\n  }\n\n  button[class*=\"review-filter\"] {\n    color: var(--color-warning);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"procurementExecution": `dXP22m9jJQ04GSUPcux7`
};
export default ___CSS_LOADER_EXPORT___;
