import { PRRecommendation } from "@/types";
// @ts-ignore
import { Typography } from "@aiops/styleguide";
import styles from './RecommendationContent.module.scss';

const RecommendationContent = ({ recommendation }) => {
  const getRecType = () => {
    if (recommendation === PRRecommendation.Approve) {
      return { text: PRRecommendation.Approve, style: styles.approved };
    }
    if (recommendation === PRRecommendation.Reject) {
      return { text: PRRecommendation.Reject, style: styles.rejected };
    }
    return { text: PRRecommendation.Review, style: styles.warning };
  }
  const { text, style } = getRecType();

  return (
      <div className={`${styles.recommendationCell}`}>
        <div className={`pill ${style}`} />
        <Typography
            className={style}
            variant="caption1c">
          {text}
        </Typography>
      </div>
  )
}

export default RecommendationContent;