// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ube77wGc0RWarY8bjDvk{width:100%;min-height:220px}.ube77wGc0RWarY8bjDvk div[class*=MuiPaper-root]{flex-direction:row;justify-content:space-between;min-height:220px}.ube77wGc0RWarY8bjDvk div[class*=MuiPaper-root]:not(:has(div[class*=loader-container])){align-items:flex-start}.ube77wGc0RWarY8bjDvk div[class*=MuiPaper-root] div[class*=chart-wrapper]{flex:1 1 50%}.ube77wGc0RWarY8bjDvk div[class*=MuiPaper-root] div[class*=chart-wrapper] div[class*=barChart] canvas{width:330px;height:160px}.ube77wGc0RWarY8bjDvk div[class*=MuiPaper-root] div[class*=chart-wrapper] div[class*=doughnutChart] canvas{width:160px;height:160px;margin-right:20px}`, "",{"version":3,"sources":["webpack://./src/modules/LandingPage/components/DashboardCharts/DashboardCharts.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,gBAAA,CACA,gDACE,kBAAA,CACA,6BAAA,CACA,gBAAA,CACA,wFACE,sBAAA,CAGF,0EACE,YAAA,CAEE,sGACE,WAAA,CACA,YAAA,CAIF,2GACE,WAAA,CACA,YAAA,CACA,iBAAA","sourcesContent":[".dashboardCharts {\n  width: 100%;\n  min-height: 220px;\n  div[class*=\"MuiPaper-root\"] {\n    flex-direction: row;\n    justify-content: space-between;\n    min-height: 220px;\n    &:not(:has(div[class*=\"loader-container\"])) {\n      align-items: flex-start;\n    }\n\n    div[class*=\"chart-wrapper\"]{\n      flex: 1 1 50%;\n      div[class*=\"barChart\"] {\n        canvas {\n          width: 330px;\n          height: 160px;\n        } \n      }\n      div[class*=\"doughnutChart\"] {\n        canvas {\n          width: 160px;\n          height: 160px;\n          margin-right: 20px;\n        } \n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboardCharts": `ube77wGc0RWarY8bjDvk`
};
export default ___CSS_LOADER_EXPORT___;
