export const StatusFilterButtons = [
    {
        id: 'Pending',
        text: 'Pending',
        selected: false,
        disabled: false,
    },
    {
        id: 'In Progress',
        text: 'In Progress',
        selected: false,
        disabled: false,
    },
    {
        id: 'On Hold',
        text: 'On Hold',
        selected: false,
        disabled: false,
    },
    {
        id: 'Completed',
        text: 'Completed',
        selected: false,
        disabled: false,
    }
];

export const RecommendationFilterButtons = [
    {
        id: 'Approve',
        text: 'Approve',
        selected: false,
        disabled: false,
        classNames: 'approve-filter'
    },
    {
        id: 'Review',
        text: 'Review',
        selected: false,
        disabled: false,
        classNames: 'review-filter'
    },
    {
        id: 'Reject',
        text: 'Reject',
        selected: false,
        disabled: false,
        classNames: 'reject-filter'
    }
]