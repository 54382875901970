// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.H7mJVmdFQAA4XcbPWMxA{display:flex;justify-content:space-between;align-items:flex-end;margin-bottom:8px}.w1BNX1OdplhIqNSjevxj{margin-top:24px}.w1BNX1OdplhIqNSjevxj *[class*=header-validation]{margin-bottom:24px}`, "",{"version":3,"sources":["webpack://./src/modules/PRDetails/components/LineItemViewSwitcher/LineItemViewSwitcher.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,oBAAA,CACA,iBAAA,CAGF,sBACE,eAAA,CACA,kDACE,kBAAA","sourcesContent":[".lineItemTableViewHeader{\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-end;\n  margin-bottom: 8px;\n}\n\n.headerRuleValidation {\n  margin-top: 24px;\n  *[class*=\"header-validation\"] {\n    margin-bottom: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lineItemTableViewHeader": `H7mJVmdFQAA4XcbPWMxA`,
	"headerRuleValidation": `w1BNX1OdplhIqNSjevxj`
};
export default ___CSS_LOADER_EXPORT___;
