import { useQuery } from "@tanstack/react-query";
import { getRequisitionList } from "@/services/requisition.requests";
// @ts-ignore
import { FilterButtonProps } from '@aiops/styleguide';
import { getStatusQueryParam } from "@/services/apihelper";
import { FilterType, SortByType } from "@/modules/LandingPage/components/DashboardTable/type";
import { PrTableConfig } from "@/modules/LandingPage/components/DashboardTable/config";

export const useRequisitionsQuery = (
  currentPage: number, 
  resultsPerPage: number, 
  statusFilter: FilterButtonProps[], 
  recommendationFilter: FilterButtonProps[],
  searchFilter: string,
  sortBy: SortByType
) => {
    const res = useQuery({
      queryKey: ["requisitionList", resultsPerPage, currentPage, searchFilter, statusFilter, recommendationFilter, sortBy],
      queryFn: () => 
      getRequisitionList({ 
        page: currentPage, 
        resultsPerPage, 
        procurement_status: getStatusQueryParam(statusFilter, "statusFilter" as FilterType), 
        recommendation_status: getStatusQueryParam(recommendationFilter, "recommendationFilter" as FilterType),
        search: searchFilter,
        sortBy: { column: sortBy.column ? PrTableConfig?.headerCells?.[sortBy.column]?.beField : "", order: sortBy.order }

      }),
      refetchOnWindowFocus: false,
      retry: false,
      onError: (error) => {
        console.error(error);
      },
    });
    return res;
};