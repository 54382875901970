// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uOyMxGtnSYoto5lgahH1{background-color:inherit;border:none;color:var(--white);text-decoration:underline;text-underline-offset:1px}.uOyMxGtnSYoto5lgahH1:hover{color:var(--link-hover-color)}.B3C08jP5r3m70Ls6O2PD p{font-size:14px}.tRLH3U4ZU7S8tEzfcQUM{display:flex;align-items:center;justify-content:center;gap:8px}.tRLH3U4ZU7S8tEzfcQUM img{width:16px;height:16px}.tRLH3U4ZU7S8tEzfcQUM p{font-size:14px}`, "",{"version":3,"sources":["webpack://./src/modules/LandingPage/components/DashboardTable/DashboardTable.module.scss"],"names":[],"mappings":"AAAA,sBACI,wBAAA,CACA,WAAA,CACA,kBAAA,CACA,yBAAA,CACA,yBAAA,CAEA,4BAEE,6BAAA,CAIJ,wBACE,cAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,OAAA,CACA,0BACE,UAAA,CACA,WAAA,CAEF,wBACE,cAAA","sourcesContent":[".linkButton {\n    background-color: inherit;\n    border: none;\n    color: var(--white);\n    text-decoration: underline;\n    text-underline-offset: 1px;\n\n    &:hover {\n      //TODO @@STYLEGUIDE add link hover color to styleguide\n      color: var(--link-hover-color);\n    }\n}\n.prLinkButton {\n  p {\n    font-size: 14px;\n  }\n}\n.daysOpen {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 8px;\n    img {\n      width: 16px;\n      height: 16px;\n    }\n    p {\n      font-size: 14px;;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkButton": `uOyMxGtnSYoto5lgahH1`,
	"prLinkButton": `B3C08jP5r3m70Ls6O2PD`,
	"daysOpen": `tRLH3U4ZU7S8tEzfcQUM`
};
export default ___CSS_LOADER_EXPORT___;
