import { axiosInstance } from "@/services/api.setup";
import { ENDPOINTS } from "@/constants/endPoints";
import { 
  QueryParamsRequisitionList, 
  RequisitionListType, 
  URLParamsRequisitionList 
} from "@/modules/LandingPage/components/DashboardTable/type";
import { 
  mapDashboardAnalyticsResponse, 
  mapRequisitionDetailsResponse, 
  mapRequisitionListResponse 
} from "./apihelper";
/*
 * GET call to get the list of purchase requisitions
 * */
export const getRequisitionList = async ({
  page,
  resultsPerPage,
  procurement_status,
  recommendation_status,
  search,
  sortBy
}: QueryParamsRequisitionList): Promise<RequisitionListType> => {
  const url = "https://btp-backend.dev.aiops-d.cloud/procurement_execution" + ENDPOINTS.PURCHASE_REQUISITIONS;

  const queryParam = new URLSearchParams({
    page: page <= 0 ? "1" : (page + 1).toString(), // API is 1-indexed while UI is 0-indexed
    size: resultsPerPage ? resultsPerPage.toString() : "",
    ...(sortBy.column && { sort_by: sortBy?.column ? `${sortBy.column}:${sortBy.order}` : "" }),
    ...(procurement_status && { procurement_status }),
    ...(recommendation_status && { recommendation_status }),
    ...(search && { search: search.toString() }),
  } as URLParamsRequisitionList).toString();

  const config = {
    method: "GET",
    url: `${url}?${queryParam}`,
  };

  const response = await axiosInstance.request(config);
  return mapRequisitionListResponse(response.data);
};

export const getDashboardAnalytics = async () => {
  const url = "https://btp-backend.dev.aiops-d.cloud/procurement_execution" + ENDPOINTS.DASHBOARD_ANALYTICS;

  const config = {
    method: "GET",
    url,
  };

  const response = await axiosInstance.request(config);
  return mapDashboardAnalyticsResponse(response.data);
}

/**
 * GET call to get the details of a purchase requisition
 * @param id
 *
 * @returns {Promise<any>} A Promise that resolves in the details of the purchase requisition
 */

export const getRequisitionDetails = async (
  id: string,
  recommendation_status: string
): Promise<any> => {
  const queryParam = new URLSearchParams({
    ...(recommendation_status && { recommendation_status }),
  } as URLParamsRequisitionList).toString();

  const config = {
    method: "GET",
    url: `/${id}?${queryParam}`,
  };
  const response = await axiosInstance.request(config);
  return mapRequisitionDetailsResponse(response.data);
};