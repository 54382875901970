// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.C7xA70Zy2GSQ4AdRYWYT{display:flex;justify-content:space-between;align-items:center;margin-bottom:16px}.C7xA70Zy2GSQ4AdRYWYT .ViiC8WBkAXAZ11L3Mf_H{margin-left:8px}`, "",{"version":3,"sources":["webpack://./src/components/TableActionsWrapper/TableActionsWrapper.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,kBAAA,CACA,4CACE,eAAA","sourcesContent":[".tableActionsWrapper {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 16px;\n  .MuiButton-root {\n    margin-left: 8px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableActionsWrapper": `C7xA70Zy2GSQ4AdRYWYT`,
	"MuiButton-root": `ViiC8WBkAXAZ11L3Mf_H`
};
export default ___CSS_LOADER_EXPORT___;
