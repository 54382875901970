// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rTrl3345J6J1zcIBdbFm{display:flex;align-items:center;justify-content:center;gap:9px}.rTrl3345J6J1zcIBdbFm div[class*=pill]{height:16px;width:16px;border-radius:16px}.rTrl3345J6J1zcIBdbFm div[class*=pill].RSnQBPz_tjqjpv5rk3Dw{background-color:var(--color-success)}.rTrl3345J6J1zcIBdbFm div[class*=pill].blguTQnuxLGw1GrY_XzR{background-color:var(--color-error)}.rTrl3345J6J1zcIBdbFm div[class*=pill].v877F_icAXnkx4GuBQ1Q{background-color:var(--color-warning)}.rTrl3345J6J1zcIBdbFm p.RSnQBPz_tjqjpv5rk3Dw{color:var(--color-success)}.rTrl3345J6J1zcIBdbFm p.blguTQnuxLGw1GrY_XzR{color:var(--color-error)}.rTrl3345J6J1zcIBdbFm p.v877F_icAXnkx4GuBQ1Q{color:var(--color-warning)}`, "",{"version":3,"sources":["webpack://./src/components/RecommendationContent/RecommendationContent.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,OAAA,CACA,uCACE,WAAA,CACA,UAAA,CACA,kBAAA,CACA,4DACE,qCAAA,CAEF,4DACE,mCAAA,CAEF,4DACE,qCAAA,CAIF,6CACE,0BAAA,CAEF,6CACE,wBAAA,CAEF,6CACE,0BAAA","sourcesContent":[".recommendationCell {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 9px;\n  div[class*='pill'] {\n    height: 16px;\n    width: 16px;\n    border-radius: 16px;\n    &.approved {\n      background-color: var(--color-success);\n    }\n    &.rejected {\n      background-color: var(--color-error);\n    }\n    &.warning {\n      background-color: var(--color-warning);\n    }\n  }\n  p {\n    &.approved {\n      color: var(--color-success);\n    }\n    &.rejected {\n      color: var(--color-error);\n    }\n    &.warning {\n      color: var(--color-warning);\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recommendationCell": `rTrl3345J6J1zcIBdbFm`,
	"approved": `RSnQBPz_tjqjpv5rk3Dw`,
	"rejected": `blguTQnuxLGw1GrY_XzR`,
	"warning": `v877F_icAXnkx4GuBQ1Q`
};
export default ___CSS_LOADER_EXPORT___;
