// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bGiTUKeauDmp0AldfzTS .M67deM2klbEGGIJYulNd{display:flex;justify-content:space-between;align-items:center;width:100%}.bGiTUKeauDmp0AldfzTS .M67deM2klbEGGIJYulNd div{display:flex;align-items:center;gap:8px}`, "",{"version":3,"sources":["webpack://./src/modules/PRDetails/components/LineItemDetail/LineItemDetail.module.scss"],"names":[],"mappings":"AACE,4CACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,UAAA,CACA,gDACE,YAAA,CACA,kBAAA,CACA,OAAA","sourcesContent":[".lineItemDetail {\n  .lineItemDetailHeader {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    div{\n      display: flex;\n      align-items: center;\n      gap: 8px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lineItemDetail": `bGiTUKeauDmp0AldfzTS`,
	"lineItemDetailHeader": `M67deM2klbEGGIJYulNd`
};
export default ___CSS_LOADER_EXPORT___;
