//@ts-ignore
import { Table, TableFooterPaginationProps } from "@aiops/styleguide";
import { PrTableConfig } from './config';
import { DEFAULT_RES_PER_PAGE_OPTS } from '@/config';
import { useRequisitionsQuery } from "@/hooks";
import { useEffect } from "react";
import { FlagDaysOpen, getNewSortOrder, LinkText } from "./helper";
import { usePrTableContext } from "../../context/PRTableContext";
import { RecommendationContent } from "@/components";

export const DashboardTable = () => {
  const { 
    selectedReqIds, 
    setSelectedReqIds, 
    statusFilter, 
    recommendationFilter, 
    searchFilter,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    sortBy,
    setSortBy,
    reloadTable
  } = usePrTableContext();

  const {
    data: {
      requisitions,
      totalResults
    } =
    {
      requisitions: [],
      totalResults: 0
    },
    isFetching,
    refetch
  } = useRequisitionsQuery(currentPage, rowsPerPage, statusFilter, recommendationFilter, searchFilter, sortBy);

  useEffect(() => {
    refetch().then((r) => r);
  }, [currentPage, rowsPerPage, searchFilter, statusFilter, recommendationFilter, reloadTable]);

  const pagination: TableFooterPaginationProps = {
    currentPage,
    rowsPerPage,
    rowsPerPageOptions: DEFAULT_RES_PER_PAGE_OPTS,
    totalResults,
    onNextPage: () => {
      const maxPageNum = Math.ceil(totalResults / rowsPerPage) - 1;
      setCurrentPage(Math.min(currentPage + 1, maxPageNum));
    },
    onPreviousPage: () => setCurrentPage(Math.max(currentPage - 1, 0)),
    onSelectRowsPerPage: (r: number) => {
      // When changing the number of results per page, dump the user back to the
      // first page.
      setCurrentPage(0);
      setRowsPerPage(r);
    }
  }

  const selectability = {
    rowIsSelected: (row) => {
      return selectedReqIds?.includes(row?.reqId)
    },
    rowIsSelectable: (row: Record<string, any>) => true,
    selectAllCheckbox: true,
    onSelectAllToggle: (checked: boolean) => {
      let newReqIds: string[] = [...selectedReqIds];

      if (!checked) {
        newReqIds = [];
      } else {
        newReqIds = requisitions.map((r) => r.reqId);
      }
      return setSelectedReqIds(newReqIds);
    },
    onSelectRowToggle: (checked: boolean, row: any) => {
      let newReqIds: string[] = [...selectedReqIds];

      if (!checked) {
        newReqIds = newReqIds.filter((id) => id !== row?.reqId);
      } else {
        newReqIds.push(row.reqId);
      }
      return setSelectedReqIds(newReqIds);
    }
  };

  const headerCells = {};

  PrTableConfig.columnKeys.forEach((col) => {
    const field = col;
    headerCells[field] = {
      title: PrTableConfig.headerCells[field].title,
      isCurrentSortColumn: sortBy.column === field,
      // daysOpen backend "descending" sorting returns the youngest PRs first so sort order is reversed here to return the oldest PRs on initial click and show the descending arrow (down) when oldest PRs are first results
      sortedAscending: (sortBy.order === "asc" && field !== "daysOpen") || (field === "daysOpen" && sortBy.order === "desc"),
      onClick: () => {
        setSortBy({
          column: field,
          order: getNewSortOrder(sortBy, field)
        })
      }
    }
  });

  return (
    <Table
      tableIsLoading={isFetching}
      columnKeys={PrTableConfig.columnKeys}
      headerCells={headerCells}
      selectability={selectability}
      rows={requisitions.map((row) => ({
        ...row,
        daysOpen: <FlagDaysOpen daysOpen={row.daysOpen} reqNumber={row.reqId}/>,
        reqIdLink: <LinkText reqNumber={row.reqId} source={row.erpSource} />,
        recommendation: <RecommendationContent recommendation={row.recommendation} />
      }))}
      pagination={pagination}
    />
  )
}