//@ts-ignore
import { TableProps } from "@aiops/styleguide";

export const PrTableConfig : TableProps = {
  headerCells: {
    reqIdLink: { title: 'Requisition ID', key: 1, beField: 'requisition_id' },
    daysOpen: { title: 'Days Open', key: 2, beField: 'created_at' },
    numLines: { title: 'No. of Lines', key: 3, beField: 'line_item_count' },
    prDate: { title: 'PR Date', key: 4, beField: 'requisition_date' },
    totalPRAmount: { title: 'Amount', key: 5, beField: 'TotalCost.pr_amount' },
    reviewerGroup: { title: 'Reviewer Group', key: 6, beField: 'reviewer_group' },
    reviewer: { title: 'Reviewer', key: 7, beField: 'reviewer' },
    requester: { title: 'Requester', key: 8, beField: 'Requester.requester' },
    status: { title: 'Status', key: 9, beField: 'procurement_status' },
    recommendation: { title: 'Recommendation', key: 10, beField: 'recommendation_status' },
  },
  columnKeys: ['reqIdLink', 'daysOpen', 'numLines', 'prDate', 'totalPRAmount', 'reviewerGroup', 'reviewer', 'requester', 'status', 'recommendation'],
}