// @ts-ignore
import { StyledDropdown, MenuItem } from "@aiops/styleguide";
import { usePRDetailsContext } from "@/modules/PRDetails/context";
import styles from './LineItemWidget.module.scss';


const getLineItemText = (lineNum : any) => {
  return `Line ${lineNum}`;
}

const LineItemWidget = () => {
  const { prDetails, selectedLineItem, setSelectedLineItem } = usePRDetailsContext();

  return (
      <div className={styles.lineItemWidget}>
        <StyledDropdown
            size="small"
            label="Select"
            selectedValue={selectedLineItem}
            onChange={(e: any) => {
              setSelectedLineItem(e.target.value);
            }}
            renderValue={(selectedValue: any) => {
              return getLineItemText(selectedValue.lineNum);
            }}
            options={prDetails?.lineItemsSummary?.lineItems || []}
            renderMenuItem={(lineItem: any) => {
              return <MenuItem value={lineItem} key={lineItem.lineNum}>{getLineItemText(lineItem.lineNum)}</MenuItem>
            }}
        />
      </div>
  )
};

export default LineItemWidget;