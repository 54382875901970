// @ts-ignore
import { Typography } from "@aiops/styleguide";
import styles from './RecommendationPill.module.scss';

const RecommendationPill = ({ recommendation }) => {

  return (
    <div className={`${styles.recommendationPill} recommendation-${recommendation}`}>
      <Typography variant="caption1b" className={styles.recommendationText}>
        {recommendation}
      </Typography>
    </div>
  );
};

export default RecommendationPill;