export const DEFAULT_RES_PER_PAGE_OPTS = [
  5,
  10,
  15,
  20,
  25,
  50,
  100,
];

export const DEFAULT_RES_PER_PAGE = DEFAULT_RES_PER_PAGE_OPTS[0];

export type SortOrder = "asc" | "desc" | "";
