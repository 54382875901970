// @ts-ignore
import { Typography } from '@aiops/styleguide';
import styles from './RuleValidation.module.scss';

const RulesPolicyJustification = ({ policy, justification }) => {
  return (
    <div className={styles.policyJustification}>
      <div>
        <Typography variant="caption1c">Policy</Typography>
        <Typography variant="paragraph1-bold">{policy}</Typography>
      </div>
      <div>
        <Typography variant="caption1c">Justification</Typography>
        <Typography variant="paragraph1-bold">{justification}</Typography>
      </div>
    </div>
  );
}

export default RulesPolicyJustification;