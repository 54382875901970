// @ts-ignore
import { FilterBar, FilterButtonProps } from '@aiops/styleguide';
import styles from './TableFilter.module.scss';
import { usePrTableContext } from "@/modules/LandingPage/context/PRTableContext";
import { StatusFilterButtons, RecommendationFilterButtons } from '@/constants/filterBar.constant';
import { FilterType } from '../DashboardTable/type';

const TableFilter = () => {

    const {
        statusFilter, 
        setStatusFilter,
        recommendationFilter, 
        setRecommendationFilter,
        setCurrentPage,
    } = usePrTableContext();

    const handleFilterChange = (newState: FilterButtonProps[], filterType: FilterType) => {
        // reset the current page to 0
        setCurrentPage(0);
        /* on selection of all possible actions resets the filter to select ALL */
        const   allOrNoneSelected = newState.every((button) => button.selected);
        filterType === "statusFilter" as FilterType ? setStatusFilter(
            allOrNoneSelected ? StatusFilterButtons : newState
        ) : setRecommendationFilter(
            allOrNoneSelected ? RecommendationFilterButtons : newState
        );
    }

    const handleStatusFilterChange = (newState: FilterButtonProps[]) => {
         handleFilterChange(newState, "statusFilter" as FilterType);
    }

    const handleRecommendationFilterChange = (newState: FilterButtonProps[]) => {
        handleFilterChange(newState, "recommendationFilter" as FilterType);
    }

    return (
        <div className={styles.filterBar}>
            <FilterBar filterButtons={statusFilter} onChange={handleStatusFilterChange} />
            <FilterBar filterButtons={recommendationFilter} onChange={handleRecommendationFilterChange} />
        </div>
    );
}

export default TableFilter;