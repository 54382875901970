// @ts-ignore
import { DoughnutChartConfig, GRAPHIC_COLORS, Chart, ChartType } from "@aiops/charting";

export const getRecommendationCountConfig = (data): DoughnutChartConfig => {
  return ({
    xAxes: [{
      label: "Recommendation Count",
      mapping: [
        { label: `${data?.approve} Approve`, key: "approve", color: GRAPHIC_COLORS.success200 },
        { label: `${data?.review} Review`, key: "review", color: GRAPHIC_COLORS.warning200 },
        { label: `${data?.reject} Reject`, key: "reject", color: GRAPHIC_COLORS.danger100 }],
      key: "recommendationCount",
      isColorAxis: true
    }],
    yAxes: [{
      label: "Recommendations Count",
      key: "",
    }],
    tooltipOptions: {
      enabled: false,
    },
    legendOptions: {
      display: true,
      label: "",
      axisKey: "recommendationCount",
      axisType: "X",
      position: "right"
    },
    dataLabels: {
      display: false,
    },
    doughnutOptions: {
      cutoutPercentage: "85%",
      innerDataLabel: {
        metricKey: "total",
        label: "Total PR"
      }
    },
  })
};

export const DoughnutChart = ({ isLoading, chartData } : { isLoading: boolean, chartData?: { approve : number, reject : number, review : number, total : number } }) => {
  return (
    <div className="doughnutChart">
      <Chart
        title={"Recommendations (6 months)"}
        type={ChartType.Doughnut}
        data={chartData}
        config={getRecommendationCountConfig(chartData)}
        {...{ isLoading }}
      />
    </div>
  )
}